import { Routes, Route } from "react-router-dom";
import Home from '../pages/Home'
import Produtos from '../pages/Produtos'
import PositiveSeMuher from "../pages/PositiveSeMulher";
import Ebook from "../pages/Ebook";
import JornadaEmpresarial from "../pages/Jornada";
import Links from "../pages/Links";

function RoutesApp() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/links" element={<Links/> } />
      <Route path="/produtos" element={<Produtos />} />
      <Route path="/positive-se-mulher" element={<PositiveSeMuher />} />
      <Route path="/ebook-ser-empreendedora" element={<Ebook />} />
      <Route path="/jornada-empresarial" element={<JornadaEmpresarial/>} />
    </Routes>
  )
}


export default RoutesApp;