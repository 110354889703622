import React from 'react';
import './footer.css';
import Logo from '../../assets/imgs/logoBranca.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {

  return (
    <footer className="footer">
      <div className='content'>
        <img src={Logo} alt="Logo" />
        <div className='itens'>
          <h4>Positive Group 2023</h4>
          <p>Todos os direitos reservados</p>
          <a href='/'>Política de privacidade</a>
          <h5>Acompanhe-nos nas redes</h5>
        </div>
      </div>
      <div className='incones'>
        <a href='https://www.instagram.com/positive.group/' alt='Insta' target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href='https://youtube.com/@anapaulabacelar3047?si=sx7qDkw-Q9d1Fh6u' alt='Youtube' target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a href='https://www.facebook.com/empresarialpositive?mibextid=LQQJ4d' alt='Facebook' target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
      </div>
    </footer>
  );

};

export default Footer;
