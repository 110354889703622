import React, { useState, useEffect } from 'react';
import './positive.css'
import Bannertopo from '../../assets/imgs/BannerAna.png';
import BannertopoMobile from '../../assets/imgs/bannermpm- mobile.png';
import Logo from '../../assets/imgs/logo_positiveRosa.png';
import FotoAna from '../../assets/imgs/Anacelular.png';
import LogoP from '../../assets/imgs/P_rosa.png';
import Foto1 from '../../assets/imgs/Foto1.png';
import Foto2 from '../../assets/imgs/Foto2.png';
import Foto3 from '../../assets/imgs/slidermpm2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons';




function PositiveSeMuher() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [bodyClick, setBodyClick] = useState(false);

  useEffect(() => {
    const closeMenu = () => {
      if (bodyClick) {
        setMenuOpen(false);
        setBodyClick(false);
      }
    };

    if (menuOpen) {
      document.body.addEventListener('click', closeMenu);
    } else {
      document.body.removeEventListener('click', closeMenu);
    }

    return () => {
      document.body.removeEventListener('click', closeMenu);
    };
  }, [menuOpen, bodyClick]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuClick = () => {
    setBodyClick(true);
  };



  return (
    <div className='containerMpm'>

      <header className="header">
        <div className="logo">
          <a href='/'>
            <img src={Logo} alt='Logo' />
          </a>
        </div>
        <nav className={`menu ${menuOpen ? 'open' : ''}`} onClick={handleMenuClick}>
          <ul>
            <li><a href='/'>Home</a></li>
            <li><a href='/#sobre'>Sobre</a></li>
            <li><a href='/#produtos'>Produtos</a></li>
            <li><a href='/#positive-se-mulher' className='mpm-link'>Positive-se Mulher</a></li>
            <li><a href='/#nosso-time'>Nosso time</a></li>
            <li><a href='/#contato'>Contatos</a></li>
            <li>
              <a className='a1' href="https://wa.me/5586981563557" target='_blank' rel="noreferrer">
                <button className="cta-button">Quero ser Positive</button>
              </a>
            </li>
          </ul>
        </nav>
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </header>

      <div className='bannerMpmtopo'>
        <img src={Bannertopo} alt='Banner' />
      </div>

      <div className='bannerMpmtopoMobile'>
        <img src={BannertopoMobile} alt='Banner' />
      </div>

      <div className='content contentMpmAna'>
        <div className='AnaMpm'>
          <img src={FotoAna} alt='Foto ana' />
          <div>
            <h3>Ana Paula Bacelar</h3>
            <p>
              Apresentamos um programa de desenvolvimento pessoal e profissional dedicado a empreendedoras e aquelas com potencial empreendedor.
              <br></br>
              <br></br>
              Este movimento inspirador proporciona suporte e disponibiliza recursos valiosos para mulheres empreendedoras que buscam alcançar sucesso e equilíbrio.</p>
          </div>
        </div>
        <div className='logoP-mpm' alt='Logo'>
          <img src={LogoP} alt='Logo' />
          <div>
            <h3>Movimento Positive-se Mulher</h3>
            <p>
              Apresentamos um programa de desenvolvimento pessoal e profissional dedicado a empreendedoras e aquelas com potencial empreendedor.
              <br></br>
              <br></br>
              Este movimento inspirador proporciona suporte e disponibiliza recursos valiosos para mulheres empreendedoras que buscam alcançar sucesso e equilíbrio.</p>
          </div>
        </div>
      </div>

      <div className='content container-service-mpm'>
        <div className='content-service'>
          <img src={Foto1} alt='Foto' />
          <div>
            <h3>Alavanque seu negócio, mulher</h3>
            <p>Este programa exclusivo é projetado para mulheres que desejam elevar seus negócios a novos patamares, oferecendo orientação estratégica e inovação.</p>
            <a href='https://wa.me/5586981563557' target="_blank" rel="noreferrer"><button>Quero ser Positive</button></a>
          </div>
        </div>
        <div className='content-service'>
          <img src={Foto2} alt='Foto' />
          <div>
            <h3>Empreendedorismo com propósito</h3>
            <p>Descubra como empreender com um propósito significativo. Este programa ajudará você a alinhar seus valores pessoais com seu negócio.</p>
            <a href='https://wa.me/5586981563557' target="_blank" rel="noreferrer"><button>Quero ser Positive</button></a>
          </div>
        </div>
        <div className='content-service'>
          <img src={Foto3} alt='Foto' />
          <div>
            <h3>Planner Positive-se Mulher</h3>
            <p>Descubra como empreender com um propósito significativo. Este programa ajudará você a alinhar seus valores pessoais com seu negócio.</p>
            <a href='https://wa.me/5586981563557' target="_blank" rel="noreferrer"><button>Quero ser Positive</button></a>
          </div>
        </div>
      </div>

      <div className='container-avaliacao2 avaliacao-mpm'>
        <div className='content'>
          <h3>Não achou o que precisava?
            <br></br>
            <br></br>
            Fale conosco
          </h3>
          <a href='https://wa.me/5586981563557' target='_blanck'>
            <button>Clique aqui
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </a>
        </div>
      </div>

      <div className='avaliacao-mpm-mobile'>
        <div className='content'>
          <h3>Não achou o que precisava?<br></br>
            Fale conosco
          </h3>
          <a href='https://wa.me/5586981563557' target='_blanck'>
            <button>Clique aqui
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </a>
        </div>
      </div>

      <footer className="footer footer-mpm">
        <div className='content'>
          <img src={Logo} alt="Logo" />
          <div className='itens'>
            <h4>Positive Group 2023</h4>
            <p>Todos os direitos reservados</p>
            <a href='/'>Política de privacidade</a>
            <h5>Acompanhe-nos nas redes</h5>
          </div>
        </div>
        <div className='incones'>
          <a href='https://www.instagram.com/positive.group/' alt='Insta' target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href='https://www.instagram.com/positive.group/' alt='Youtube' target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href='https://www.instagram.com/positive.group/' alt='Facebook' target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </div>
      </footer>

    </div>
  )
}

export default PositiveSeMuher;
