import React, { useEffect} from 'react';
import './ebook.css'
import FotoEbook from "../../assets/imgs/ebook.png"
import FotosLogo from "../../assets/imgs/logos.png"
import AnaPerfil from "../../assets/imgs/ana.png"
import FotoBook from "../../assets/imgs/book.png"
import Carrosel1 from "../../assets/imgs/carrosel1.png"
import Logo from "../../assets/imgs/logo.png"
import EmailImg from "../../assets/imgs/email.png"

const Ebook = () => {
 
  const animeScroll = () => {
    const windowTop = window.pageYOffset + window.innerHeight * 0.85;
    const items = document.querySelectorAll("[data-anime]");

    items.forEach((element) => {
      if (windowTop > element.offsetTop) {
        element.classList.add("animate");
      } else {
        element.classList.remove("animate");
      }
    });
  };

  const startTimer = (duration, display) => {
    let timer = duration;
    let minutes, seconds;

    setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = minutes + ":" + seconds;

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  };

  useEffect(() => {
    const handleScroll = () => {
      animeScroll();
    };

    animeScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const duration = 60 * 24;
    const display = document.querySelector("#timer");

    startTimer(duration, display);
  }, []);

  return (
    <div className='content-ebook'>
      <div className="cabecalho-ebook">
        <p><em style={{ color: '#c54444' }}>APROVEITE!</em> Oferta por tempo Limitado</p>
        <div id="timer"></div>
      </div>
      <div className="topo-ebook">
        <img src={FotoEbook} alt="Foto ebook" data-rellax-speed="-.2"></img>
        <div className="comprar-ebook">
          <div className="perfil-ebook" data-rellax-speed="3">
            <img id="logo-ebook" src={FotosLogo} alt="Foto logos"></img>
            <h2>Ana Paula Bacelar e Positive Group <br /><i>APRESENTAM</i></h2>
          </div>
          <h1 data-rellax-speed="2">E-book Ser Emprendedora</h1>
          <h2 data-rellax-speed="2">
            O manual que vai ajudar a organizar suas ideias empreendedoras.
          </h2>
          <button><a href="https://sun.eduzz.com/1621270" target="_blank" rel="noreferrer">QUERO EMPREENDER</a></button>
        </div>
      </div>

      <div className="essebook-ebook" data-anime="up"><h2>Esse e-book foi criado por quem entende do assunto:</h2></div>
      <div className="sobremim-ebook" data-anime="up">
        <img id="perfil-ebook" src={AnaPerfil} alt="Foto Perfil"></img>
        <ul>
          <li>Administradora de empresas e especialista em análise de perfil comportamental;</li>
          <li>Consultora, mentora e treinadora de empreendedores desde 2012;</li>
          <li>Este é meu principal formato de monetização on-line;</li>
          <li>Criadora da marca Positive Solução Empresarial e do movimento Positive-se Mulher;</li>
          <li>Trabalho com empreendedorismo e inovação há mais de 10 anos. Com mais de 200 empresas atendidas.</li>
        </ul>
      </div>
      <div className="container-ebook">
        <div className="introducao-ebook" data-anime="up">
          <h3>VOCÊ TEM UMA IDEIA DE NEGÓCIO, MAS NÃO SABE O QUE FAZER, COM QUEM FALAR, NEM POR ONDE COMEÇAR?</h3>
          <p>
            Você fica perdida e precisa de um guia. Precisa do passo a passo elaborado por quem já viveu a experiência e atualmente
            ajuda inúmeros potências empreendedoras a tirarem suas ideias do papel as colocando em prática.
            Você precisa seguir um processo e é exatamente isso que vou te entregar no meu livro chamado SER EMPREENDEDORA.
            Nele você vai encontrar um processo simples e bem organizado para que possa começar a sua jornada empreendedora.
            O melhor de tudo que ele será o seu novo MANUAL DE CONSULTA e você sempre poderá encontrar todos os passos para
            estruturação do seu negócio.
            Uma coisa é fato: Nunca abra uma empresa sem antes fazer seu planejamento e seguir etapas essenciais para diminuir
            futuros riscos.
            Meu proposito é ajudar a desenvolver e resgatar recursos para realizar seu projeto de vida. De maneira leve, empática e
            eficiente.
            Vou te mostrar o caminho das pedras para que você abra a sua empresa corretamente, construindo um negócio de sucesso.
          </p>
        </div>
      </div>

      <div className="guia-ebook" data-anime="up">
        <h2>Este guia é para você que:</h2>
        <div className="descricao-ebook">
          <img src={FotoBook} alt="Foto ebook"></img>
          <ul>
            <li>Deseja empreender e não sabe por onde começar;</li>
            <br />
            <li>Quer ter um guia completo sobre o que fazer para abrir sua empresa;</li>
            <br />
            <li>Quer entender o passo a passo para organizar suas ideias empreendedoras;</li>
            <br />
            <li>Quer ter acesso a ferramentas que te ajude no processo de estruturação da sua ideia;</li>
            <br />
            <li>Quer saber as etapas que irão te guiar para que o seu modelo de negócio prospere e tome espaço no mercado.</li>
          </ul>
        </div>
        <p>Se algum desses pontos fizer sentido pra você, este é o GUIA mais importante para ter nesse exato momento.</p>
      </div>
      <h2 id="compre-ebook" data-anime="up">Compre seu E-Book</h2>
      <div className="venda-ebook" data-anime="up">
        <img src={Carrosel1} alt="Foto versão digital"></img>
        <div>
          <p>
            De <s>R$70,00</s><br />
            <span style={{ color: 'bisque' }}>por 11 X R$5,27</span><br />
            <span>ou R$49,00 <b style={{ color: 'bisque' }}>á vista</b></span>
          </p>
          <button id="button-venda-ebook" style={{ backgroundColor: 'bisque' }}>
            <a href="https://sun.eduzz.com/1621270" target="_blank" rel="noreferrer">Garantir ebook</a>
          </button>
        </div>
      </div>

      <div className='footer-ebook' data-anime="up">
        <div>
          <img src={Logo} alt="Logo"></img>
          <p>
            &copy; 2022 Todos os direitos reservados <br />
            <b>Positive Solução Empresarial</b><br />
            CNPJ 20.925.744/0001-24
          </p>
        </div>
        <div>
          <img src={EmailImg} alt="Incone email"></img>
          <p>
            <b>Duvidas ou quer falar comigo?</b><br />
            Se tiver dúvidas sobre sua compra, frete ou quiser falar conosco. <br />
            nos envie um e-mail para: <br />
            <b>contato@positiveempresarial.com.br</b>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Ebook
