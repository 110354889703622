import React, { useState } from 'react';
import './produtos.css'
import Header from '../Header';
import Footer from '../Footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Banner1 from '../../assets/imgs/Banner1produtos.png'
import Slider1 from '../../assets/imgs/slider1.png'
import Slider2 from '../../assets/imgs/sliderproduto2.png'
import Slider3 from '../../assets/imgs/sliderproduto3.png'
import Slider4 from '../../assets/imgs/sliderproduto4.png'
import Slider5 from '../../assets/imgs/sliderproduto5.png'
import Slider6 from '../../assets/imgs/sliderproduto6.png'
import Slider7 from '../../assets/imgs/sliderproduto7.png'
import Slider11 from '../../assets/imgs/sliderproduto11.png'
import Slider12 from '../../assets/imgs/sliderproduto12.png'
import Slider13 from '../../assets/imgs/sliderproduto13.png'
import Slider14 from '../../assets/imgs/sliderproduto14.png'
import Slider15 from '../../assets/imgs/sliderproduto15.png'
import Slider16 from '../../assets/imgs/sliderproduto16.png'
import Slider17 from '../../assets/imgs/sliderproduto17.png'
import Slider18 from '../../assets/imgs/sliderproduto18.png'
import Slider333 from '../../assets/imgs/Slider333.png'
import Slider444 from '../../assets/imgs/Slider444.png'


function Produtos() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);
  const [currentSlide3, setCurrentSlide3] = useState(0);
  const numberOfSlides = 7;
  const numberOfSlides2 = 8;
  const numberOfSlides3 = 4;


  const CustomArrow = ({ onClick, direction }) => (
    <button onClick={onClick} className={`custom-${direction}-button`}>
      {direction === 'prev' ? '←' : '→'}
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const settings3 = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    responsive: [
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleSlideChange = (oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  };
  const handleSlideChange2 = (oldIndex, newIndex) => {
    setCurrentSlide2(newIndex);
  };
  const handleSlideChange3 = (oldIndex, newIndex) => {
    setCurrentSlide3(newIndex);
  };


  return (
    <div>
      <Header />

      <Slider {...settings} className='sliderTop'>
        <div className="slider-item">
          <a href="https://wa.me/5586981563557" target='_blanck'>
            <img src={Banner1} alt='Banner' className='img-banner' />
          </a>
          <p>Produtos que vão deixar sua empresa positive!</p>
          <a href="https://wa.me/5586981563557" target='_blanck'>
            <button className='btn-produto'>Quero ser positive</button>
          </a>
        </div>
      </Slider>

      <div className='content content-consult'>
        <h2><svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
          <path d="M40.8335 14.2917H8.16683C5.91167 14.2917 4.0835 16.1199 4.0835 18.375V38.7917C4.0835 41.0469 5.91167 42.875 8.16683 42.875H40.8335C43.0887 42.875 44.9168 41.0469 44.9168 38.7917V18.375C44.9168 16.1199 43.0887 14.2917 40.8335 14.2917Z" stroke="#6A7FFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M32.6668 42.875V10.2083C32.6668 9.12537 32.2366 8.08675 31.4708 7.32098C30.7051 6.55521 29.6665 6.125 28.5835 6.125H20.4168C19.3339 6.125 18.2953 6.55521 17.5295 7.32098C16.7637 8.08675 16.3335 9.12537 16.3335 10.2083V42.875" stroke="#6A7FFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
          Consultoria de negócios
        </h2>
        <div className="progress-bar">
          {Array.from({ length: numberOfSlides }).map((_, index) => (
            <div
              key={index}
              className={`progress-dot ${index === currentSlide ? 'active' : ''}`}
            />
          ))}
        </div>

        <Slider {...settings3} className="carrosel carrosel-consult" beforeChange={handleSlideChange}>
          <div className="carousel-card">
            <img src={Slider1} alt="Imagem 2" />
            <h3>Planejamento estratégico</h3>
            <p>Nosso serviço de Planejamento Estratégico ajuda sua empresa a definir metas claras, identificar oportunidades de crescimento e criar um roteiro práticos para o sucesso a longo prazo.</p>
            <a href='https://wa.me/5586981563557' target='_blanck'>
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider2} alt="Imagem 2" />
            <h3>Marketing e vendas</h3>
            <p>Com a nossa consultoria de Marketing e Vendas, você aprenderá as estratégias mais eficazes para atrair e conquistar clientes, aumentando sua receita e visibilidade de mercado.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider3} alt="Imagem 2" />
            <h3>Inovação</h3>
            <p>Explore novas ideias e abordagens para a inovação em seu negócio. Nossa consultoria em Inovação o guiará na implementação de práticas inovadoras para se destacar da concorrência.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider4} alt="Imagem 2" />
            <h3>Registro de marca</h3>
            <p>Proteja sua identidade de marca. Oferecemos assistência especializada no registro de marcas, garantindo a segurança e reconhecimento de sua marca no mercado.</p>
            <a href='https://wa.me/5586981563557' target='_blanck'>
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider5} alt="Imagem 2" />
            <h3>Jornada digital</h3>
            <p>Navegue pela jornada digital com sucesso. Ajudamos você a se adaptar às mudanças digitais e a aproveitar as oportunidades oferecidas pelo mundo online.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider6} alt="Imagem 2" />
            <h3>Transformação digital</h3>
            <p>Este serviço orienta sua empresa na transformação digital, capacitando-a a adotar tecnologias inovadoras para melhorar a eficiência e a experiência do cliente.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider7} alt="Imagem 2" />
            <h3>Desenvolvimento de pessoas</h3>
            <p>Investir em sua equipe é crucial. Oferecemos soluções de desenvolvimento de pessoal para melhorar as habilidades e o desempenho de seus funcionários.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
        </Slider>
      </div>

      <div className='content content-consult'>
        <h2>
          <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
            <path d="M24.5002 4.08337L44.9168 17.3542V31.6459L24.5002 44.9167L4.0835 31.6459V17.3542L24.5002 4.08337Z" stroke="#6A7FFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.5 44.9167V31.6459" stroke="#6A7FFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M44.9168 17.3541L24.5002 31.6458L4.0835 17.3541" stroke="#6A7FFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.0835 31.6458L24.5002 17.3541L44.9168 31.6458" stroke="#6A7FFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.5 4.08337V17.3542" stroke="#6A7FFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Treinamentos
        </h2>
        <div className="progress-bar">
          {Array.from({ length: numberOfSlides2 }).map((_, index) => (
            <div
              key={index}
              className={`progress-dot ${index === currentSlide2 ? 'active' : ''}`}
            />
          ))}
        </div>

        <Slider {...settings3} className="carrosel carrosel-consult" beforeChange={handleSlideChange2}>
          <div className="carousel-card">
            <img src={Slider11} alt="Imagem 2" />
            <h3>Usando o instagram para aumentar vendas</h3>
            <p> Domine as táticas do Instagram para impulsionar suas vendas e expandir sua presença nas redes sociais.</p>
            <a href='https://wa.me/5586981563557' target='_blanck'>
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider12} alt="Imagem 2" />
            <h3>Estratégias de MKT Digital para vendas online</h3>
            <p>Aprenda as estratégias mais recentes do marketing digital para aumentar suas vendas online de forma eficaz.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider13} alt="Imagem 2" />
            <h3>Trilha Empresarial: Fortalecendo líderes e equipes</h3>
            <p>Capacite seus líderes e equipes com habilidades essenciais de gestão e liderança.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider14} alt="Imagem 2" />
            <h3>Programa Liderança Positiva - PDLP</h3>
            <p>Desenvolva líderes excepcionais que inspirem suas equipes e promovam um ambiente de trabalho positivo.</p>
            <a href='https://wa.me/5586981563557' target='_blanck'>
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider15} alt="Imagem 2" />
            <h3>Estratégias para aumentar a criatividade e inovação</h3>
            <p>Descubra maneiras criativas de inovar e se destacar, mesmo em um mercado competitivo.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider16} alt="Imagem 2" />
            <h3>Superando desafios na gestão de equipes</h3>
            <p>Aprenda a resolver conflitos e maximizar o desempenho de sua equipe.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider17} alt="Imagem 2" />
            <h3>O papel da comunicação assertiva no mkt digital</h3>
            <p>Saiba como a comunicação eficaz pode impulsionar suas estratégias de marketing digital.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider18} alt="Imagem 2" />
            <h3>Vendas 360º : Comunique, conquiste e fidelize!</h3>
            <p>Aperfeiçoe suas habilidades de vendas, desde a prospecção até a fidelização do cliente.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
        </Slider>
      </div>

      <div className='content content-consult'>
        <h2>
          <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
            <path d="M36.75 42.875C40.1327 42.875 42.875 40.1327 42.875 36.75C42.875 33.3673 40.1327 30.625 36.75 30.625C33.3673 30.625 30.625 33.3673 30.625 36.75C30.625 40.1327 33.3673 42.875 36.75 42.875Z" stroke="#6A7FFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.25 18.375C15.6327 18.375 18.375 15.6327 18.375 12.25C18.375 8.86726 15.6327 6.125 12.25 6.125C8.86726 6.125 6.125 8.86726 6.125 12.25C6.125 15.6327 8.86726 18.375 12.25 18.375Z" stroke="#6A7FFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.25 42.875V18.375C12.25 23.2484 14.1859 27.9221 17.6319 31.3681C21.0779 34.8141 25.7516 36.75 30.625 36.75" stroke="#6A7FFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Desenvolvimento
        </h2>
        <div className="progress-bar">
          {Array.from({ length: numberOfSlides3 }).map((_, index) => (
            <div
              key={index}
              className={`progress-dot ${index === currentSlide3 ? 'active' : ''}`}
            />
          ))}
        </div>

        <Slider {...settings3} className="carrosel carrosel-consult" beforeChange={handleSlideChange3}>
          <div className="carousel-card">
            <img src={Slider3} alt="Imagem 2" />
            <h3>Criação de sitema web</h3>
            <p>Melhorar a eficiência dos processos da sua empresa, permita alcance global, e tenha uma presença online competitiva.</p>
            <a href='https://wa.me/5586981563557' target='_blanck'>
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider6} alt="Imagem 2" />
            <h3>Site institucional</h3>
            <p>Tenha uma presença online confiável e facilite a comunicação com clientes, parceiros e público em geral.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider333} alt="Imagem 2" />
            <h3>Landing page</h3>
            <p>Tenha uma maior aumentar as conversões direcionando os visitantes para uma ação, atinja objetivos de marketing e a capturar leads de forma mais eficaz.</p>
            <a href="https://wa.me/5586981563557">
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider444} alt="Imagem 2" />
            <h3>E-commerce</h3>
            <p>Venda seu produtos ou serviços pela internet, maiores oportunidades de venda em um ambiente digital.</p>
            <a href='https://wa.me/5586981563557' target='_blanck'>
              Quero esse <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
        </Slider>
      </div>

      <div className='container-avaliacao2'>
        <div className='content'>
          <h3>Quer uma avaliação gratuita para seu negócio?</h3>
          <a href='https://wa.me/5586981563557' target='_blanck'>
            <button>Clique aqui
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </a>
        </div>
      </div>


      <Footer />
    </div>
  )
}


export default Produtos;