import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import './home.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Logo from '../../assets/imgs/logoAzul.png'
import Elemento3 from '../../assets/imgs/element3.png'
import Banner1 from '../../assets/imgs/BannerRubens.png'
import Banner2 from '../../assets/imgs/BannerAna.png'
import Slider1 from '../../assets/imgs/slider1.png'
import Slider2 from '../../assets/imgs/slider2.png'
import Slider3 from '../../assets/imgs/slider3.png'
import Slider4 from '../../assets/imgs/slider4.png'
import Slider5 from '../../assets/imgs/slider5.png'
import Slider6 from '../../assets/imgs/slider6.png'
import SliderMpm1 from '../../assets/imgs/slidermpm1.png'
import SliderMpm2 from '../../assets/imgs/slidermpm2.png'
import SliderMpm3 from '../../assets/imgs/slidermpm3.png'
import SliderMpm4 from '../../assets/imgs/slidermpm4.png'


const Home = () => {

  const CustomArrow = ({ onClick, direction }) => (
    <button onClick={onClick} className={`custom-${direction}-button`}>
      {direction === 'prev' ? '←' : '→'}
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <div className='container' id='home' >
      <Header />

      <Slider {...settings} className='sliderTop'>
        <div className="slider-item">
          <a href="https://wa.me/5586981563557" target='_blanck'>
            <img src={Banner1} alt='Banner' className='img-banner' />
          </a>
          <a href="https://wa.me/5586981563557" target='_blanck'>
            <button>Quero ser positive</button>
          </a>
        </div>
        <div className="slider-item">
          <a href="https://wa.me/5586981563557" target='_blanck'>
            <img src={Banner2} alt='Banner' className='img-banner' />
          </a>
          <a href="https://wa.me/5586981563557" target='_blanck'>
          </a>
        </div>
      </Slider>

      <div id='sobre' className='content content-sobre'>
        <div className='sobre'>
          <div className='sobre-descricao'>
            <h2>Sobre a Positive</h2>
            <p>A nossa missão é capacitar e inspirar indivíduos a alcançar seu pleno potencial por meio da educação empreendedora empresarial, fornecendo recursos e oportunidades para o crescimento pessoal e profissional, transformando ideias em realidade e contribuindo positivamente para suas comunidades.</p>
          </div>
          <div className='mini-cards'>
            <div className='mini-card'>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 22.5C19.1421 22.5 22.5 19.1421 22.5 15C22.5 10.8579 19.1421 7.5 15 7.5C10.8579 7.5 7.5 10.8579 7.5 15C7.5 19.1421 10.8579 22.5 15 22.5Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 17.5C16.3807 17.5 17.5 16.3807 17.5 15C17.5 13.6193 16.3807 12.5 15 12.5C13.6193 12.5 12.5 13.6193 12.5 15C12.5 16.3807 13.6193 17.5 15 17.5Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>              <h4>+10 anos de experiência</h4>
            </div>
            <div className='mini-card'>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 22.5C19.1421 22.5 22.5 19.1421 22.5 15C22.5 10.8579 19.1421 7.5 15 7.5C10.8579 7.5 7.5 10.8579 7.5 15C7.5 19.1421 10.8579 22.5 15 22.5Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 17.5C16.3807 17.5 17.5 16.3807 17.5 15C17.5 13.6193 16.3807 12.5 15 12.5C13.6193 12.5 12.5 13.6193 12.5 15C12.5 16.3807 13.6193 17.5 15 17.5Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <h4>+500 empresas atendidas</h4>
            </div>
          </div>
        </div>
        <div className='sobre-cards'>
          <div className='card'>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path d="M15 25V12.5" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M22.5 25V5" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7.5 25V20" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <h3>Pessoa</h3>
            <p>Formamos profissionais capacitados nas áreas de mentoria, consultoria, palestras, gestão, liderança e empreendedorismo.</p>
          </div>
          <div className='card'>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path d="M22.5 26.25C24.5711 26.25 26.25 24.5711 26.25 22.5C26.25 20.4289 24.5711 18.75 22.5 18.75C20.4289 18.75 18.75 20.4289 18.75 22.5C18.75 24.5711 20.4289 26.25 22.5 26.25Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7.5 11.25C9.57107 11.25 11.25 9.57107 11.25 7.5C11.25 5.42893 9.57107 3.75 7.5 3.75C5.42893 3.75 3.75 5.42893 3.75 7.5C3.75 9.57107 5.42893 11.25 7.5 11.25Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M16.25 7.5H20C20.663 7.5 21.2989 7.76339 21.7678 8.23223C22.2366 8.70107 22.5 9.33696 22.5 10V18.75" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7.5 11.25V26.25" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <h3>Estratégia</h3>
            <p>Transformamos empresas com estratégias inovadoras, destacando-nos pelo impacto e excelência na consultoria empresarial.</p>
          </div>
          <div className='card'>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 22.5C19.1421 22.5 22.5 19.1421 22.5 15C22.5 10.8579 19.1421 7.5 15 7.5C10.8579 7.5 7.5 10.8579 7.5 15C7.5 19.1421 10.8579 22.5 15 22.5Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 17.5C16.3807 17.5 17.5 16.3807 17.5 15C17.5 13.6193 16.3807 12.5 15 12.5C13.6193 12.5 12.5 13.6193 12.5 15C12.5 16.3807 13.6193 17.5 15 17.5Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <h3>Gestão</h3>
            <p>Serviços que se concentram na estrutura, no modelo de negócios, na gestão e nas operações.</p>
          </div>
        </div>
      </div>

      <div className='content-jornada'>
        <div className='content'>
          <img src={Logo} alt='Logo' className='logo-jornada' />
          <hr></hr>
          <p>Descubra que a jornada empresarial pode ser incrível com a Positive Group!
            <br></br>
            <br></br>
            São mais de 10 anos de experiência, sendo a parceira de confiança para decisões estratégicas, inovação e resultados brilhantes em seu negócio.
            <br></br>
            <br></br>
            Capacitamos empreendedores com consultorias especializadas, mentorias personalizadas e programas de alto impacto. Junte-se a nós nesta emocionante jornada empresarial e leve o seu negócio ao próximo nível.
            <br></br>
            <br></br>
            Vamos construir um futuro brilhante juntos!</p>
          <img src={Elemento3} className='element' alt='Logo' />
        </div>
      </div>

      <div className='container-avaliacao'>
        <div className='content'>
          <h3>Agende uma avaliação
            para o seu negócio agora mesmo!
          </h3>
          <a href='https://wa.me/5586981563557' target='_blanck'>
            <button>Clique aqui
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </a>
        </div>
      </div>

      <div className='content content-carrosel'>
        <h2 id='produtos' >Produtos</h2>
        <Slider {...settings2} className="carrosel">
          <div className="carousel-card">
            <img src={Slider1} alt="Imagem 2" />
            <h3>Descubra o seu cliente ideal</h3>
            <p>Entenda o conceito, explore maneiras de descobrir, explore ferramentas, defina sua persona e aprenda a aplicar isso na estratégia de marketing e no direcionamento da sua empresa.</p>
            <a href='https://wa.me/5586981563557' target='_blanck'>
              Saber Mais <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider2} alt="Imagem 2" />
            <h3>Inovação digital</h3>
            <p>Propriedade Intelectual, Modelagem de Negócio, E-Commerce e Transformação Digital são os pilares do sucesso empresarial na era digital. Conte conosco para orientá-lo nessa jornada.</p>
            <a href="https://wa.me/5586981563557">
              Saber Mais <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider3} alt="Imagem 2" />
            <h3>Inteligência emocional no trabalho</h3>
            <p>A inteligência emocional tem um impacto significativo no equilíbrio das relações, na qualidade de vida daqueles que a cultivam e na capacidade de tomar decisões assertivas.</p>
            <a href="https://wa.me/5586981563557">
              Saber Mais <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider4} alt="Imagem 2" />
            <h3>Trilha Empresarial: Fortalecendo líderes e equipes</h3>
            <p>Capacite seus líderes e equipes com habilidades essenciais de gestão e liderança.
            </p>
            <a href='https://wa.me/5586981563557' target='_blanck'>
              Saber Mais <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider5} alt="Imagem 2" />
            <h3>Registro de marcas</h3>
            <p>Proteja sua identidade de marca. Oferecemos assistência especializada no registro de marcas, garantindo a segurança e reconhecimento de sua marca no mercado.</p>
            <a href="https://wa.me/5586981563557">
              Saber Mais <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
          <div className="carousel-card">
            <img src={Slider6} alt="Imagem 2" />
            <h3>Site institucional</h3>
            <p>Fortaleça a credibilidade, divulgue informações essenciais, promova produtos e serviços e facilite o contato com seus clientes.</p>
            <a href="https://wa.me/5586981563557">
              Saber Mais <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="#6A7FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
        </Slider>
        <div className='container-btn-ver'>
          <a href='/produtos'>
            <button>Ver tudo
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </a>
        </div>
      </div>

      <div id='positive-se-mulher' className='contanier-mpm'>
        <div className='mpm-saber-mais'>
          <a href='/positive-se-mulher'>
            <button>
              Saber mais
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </a>
        </div>

        <div className='content-carrosel-mpm'>
          <h2>Produtos para Elas</h2>
          <Slider {...settings2} className="carrosel content carrosel-mpm">
            <div className="carousel-card">
              <img src={SliderMpm1} alt="Imagem 2" />
              <h3>E-book Ser empreendedora</h3>
              <p>Passos para transformar sua ideia em empreendimento, auxiliando no desenvolvimento e captação de recursos para realizar seus projetos.</p>
              <a href='https://wa.me/5586981563557' target='_blanck'>
                Saber Mais <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 16L16 12L12 8" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 12H16" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </a>
            </div>
            <div className="carousel-card">
              <img src={SliderMpm2} alt="Imagem 2" />
              <h3>Planner Positive-se Mulher</h3>
              <p>Melhore a organização e a produtividade das empreendedoras - oferecemos ferramentas de organização essenciais para o dia a dia das mulheres que buscam otimização.</p>
              <a href="https://wa.me/5586981563557">
                Saber Mais <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 16L16 12L12 8" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 12H16" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </a>
            </div>
            <div className="carousel-card">
              <img src={SliderMpm3} alt="Imagem 2" />
              <h3>Alavanque seu negócio, mulher!</h3>
              <p>Delegar funções, organizar internamente, contratar talentos, promover autonomia, liderar eficazmente, otimizar processos e criar modelos de negócios escaláveis são fatores-chave para o crescimento empresarial.</p>
              <a href="https://wa.me/5586981563557">
                Saber Mais <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 16L16 12L12 8" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 12H16" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </a>
            </div>
            <div className="carousel-card">
              <img src={SliderMpm4} alt="Imagem 2" />
              <h3>Empreendedorismo com propósito</h3>
              <p>Descubra como empreender com um propósito significativo. Este programa ajudará você a alinhar seus valores pessoais com seu negócio.</p>
              <a href='https://wa.me/5586981563557' target='_blanck'>
                Saber Mais <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 16L16 12L12 8" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 12H16" stroke="#CA60D6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </a>
            </div>
          </Slider>
          <div className='container-btn-ver'>
            <a href='/positive-se-mulher'>
              <button>Ver tudo
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 16L16 12L12 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className='container-empresas'>
        <div className='divisor'>
          <h3 className='content'>Empresas que escolheram a Positive Group</h3>
        </div>
        <div className='empresas'></div>
      </div>

      <div id='nosso-time' className='nosso-time'>
        <div className='content content-nosso-time'>
          <h2>Nosso time</h2>
          <div className="cards-time">
            <div className="cards card1">
              <div className="overlay"></div>
              <h3>Ana Paula</h3>
              <hr />
              <p>Administradora, consultora, mentora e treinadora com
                foco em empreendedorismo, comportamento empreendedor,
                vendas e inovação.</p>
            </div>
            <div className="cards card2">
              <div className="overlay"></div>
              <h3>Rubens Rodrigues</h3>
              <hr />
              <p>Analista e desenvolvedor WEB Atuando com transformação
                digital. Instrutor com foco em Empreendedorismo e
                Perfis comportamentais.</p>
            </div>
            {/* <div className="cards card3">
              <div className="overlay"></div>
              <h3>Ana Suellen</h3>
              <hr />
              <p>Consultora empresarial e instrutora na área de Gestão
                de Pessoas, Inovação e Empreendedorismo.</p>
            </div> */}
            <div className="cards card4">
              <div className="overlay"></div>
              <h3>Adriana Alvina</h3>
              <hr />
              <p>Administradora e professora, com foco de atuação em
                Educação Empreendedora e E-commerce.</p>
            </div>
            <div className="cards card5">
              <div className="overlay"></div>
              <h3>André Felipe</h3>
              <hr />
              <p>Marketing Manager e Consultor voltado para posicionamento estratégico em mídias e marketing digital.</p>
            </div>
            <div className="cards card6">
              <div className="overlay"></div>
              <h3>Ravena Silva</h3>
              <hr />
              <p>Jornalista, atuando com produção de conteudo e marketing digital. Instrutora nas áreas de Inovação e empreendedorismo.</p>
            </div>
            <div className="cards card7">
              <div className="overlay"></div>
              <h3>Isabela Barbosa</h3>
              <hr />
              <p>Consultora e instrutora com foco na comunicação empresarial, empreendedorismo, marketing e vendas.</p>
            </div>
            <div className="cards card8">
              <div className="overlay"></div>
              <h3>Juliana Ribeiro</h3>
              <hr />
              <p>Formada em economia, Atuante com foco em analise de dados inovação e planejamento estratégico.</p>
            </div>
            {/* <div className="cards card9">
              <div className="overlay"></div>
              <h3>Erlane Bacelar</h3>
              <hr />
              <p>lorem iojoiwejcioe cweiociweocniowenciowec eocnoiwechiowenc weiocioewjcoiewhcoie coewcnioej cweiocniowe</p>
            </div> */}
            {/* <div className="cards card10">
              <div className="overlay"></div>
              <h3>Catarina Nascimento</h3>
              <hr />
              <p>lorem iojoiwejcioe cweiociweocniowenciowec eocnoiwechiowenc weiocioewjcoiewhcoie coewcnioej cweiocniowe</p>
            </div> */}
            {/* <div className="cards card11">
              <div className="overlay"></div>
              <h3>Isabel Rodrigues</h3>
              <hr />
              <p>lorem iojoiwejcioe cweiociweocniowenciowec eocnoiwechiowenc weiocioewjcoiewhcoie coewcnioej cweiocniowe</p>
            </div> */}
            <div className="cards card12">
              <div className="overlay"></div>
              <h3>Allyson Henrique</h3>
              <hr />
              <p>Analista com foco em levantamento e análise de dados.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='container-avaliacao2'>
        <div className='content'>
          <h3>Agende uma avaliação
            para o seu negócio agora mesmo!
          </h3>
          <a href='https://wa.me/5586981563557' target='_blanck'>
            <button>Clique aqui
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 16L16 12L12 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </a>
        </div>
      </div>

      <Footer />
      <div id='contato' />
    </div >
  )
};



export default Home;
