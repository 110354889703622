import React, { useState, useEffect } from 'react';
import './header.css';
import Logo from '../../assets/imgs/logoBranca.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [bodyClick, setBodyClick] = useState(false);

  useEffect(() => {
    const closeMenu = () => {
      if (bodyClick) {
        setMenuOpen(false);
        setBodyClick(false);
      }
    };

    if (menuOpen) {
      document.body.addEventListener('click', closeMenu);
    } else {
      document.body.removeEventListener('click', closeMenu);
    }

    return () => {
      document.body.removeEventListener('click', closeMenu);
    };
  }, [menuOpen, bodyClick]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuClick = () => {
    setBodyClick(true);
  };

  return (
    <header className="header">
      <div className="logo">
        <a href='/'>
          <img src={Logo} alt='Logo' />
        </a>
      </div>
      <nav className={`menu ${menuOpen ? 'open' : ''}`} onClick={handleMenuClick}>
        <ul>
          <li><a href='/'>Home</a></li>
          <li><a href='/#sobre'>Sobre</a></li>
          <li><a href='/#produtos'>Produtos</a></li>
          <li><a href='/#positive-se-mulher' className='mpm-link'>Positive-se Mulher</a></li>
          <li><a href='/#nosso-time'>Nosso time</a></li>
          <li><a href='/#contato'>Contatos</a></li>
          <li>
            <a className='a1' href="https://wa.me/5586981563557" target='_blank' rel="noreferrer">
              <button className="cta-button">Quero ser Positive</button>
            </a>
          </li>
        </ul>
      </nav>
      <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </header>
  );
};

export default Header;
